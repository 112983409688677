/* eslint-disable @typescript-eslint/no-explicit-any */
import { runInAction } from 'mobx'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import styled from 'styled-components/macro'
import { TickFormatter } from '@visx/axis'
import { NumberValue } from 'd3-scale'
import tw from 'twin.macro'
import { toast } from 'react-hot-toast'
import { SliceDto, ThreadDto, TraceDataDto } from 'components/ps-chart/models/TraceDataDto'
import { DetailsView } from 'components/ps-chart/details-view/DetailsView'
import { SessionsLeftBar } from 'components/cco/SessionsLeftBar'
import { ChartExtensions } from 'components/ps-chart/PsChartExtensions'
import { ScrolledChart } from 'components/ps-chart/PsChart'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { toastWithTitle } from 'components/StyledToaster'
import { useToaster } from 'hooks/useToaster'
import { ChartDataStore } from 'components/ps-chart/stores/ChartDataStore'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { useApi } from 'contexts/di-context'
import { NamedLinkDto, NamedLinkType } from 'api/models'
import { LineGraph } from 'components/cco/LineGraph'
import { TableStateToggleButton, TableSummaryView } from 'components/cco/TableStateToggleButton'
import { EndpointsSearch } from 'components/cco/EndpointsSearch'
import { SearchAction } from 'components/ps-chart/actions-panel/SearchAction'
import { RenderTypeModeAction } from 'components/ps-chart/actions-panel/RenderTypeModeAction'

// hardcoded for now, maybe we can think of a better way to do this or better defaults?
const tracePageParams = {
  flowProjectLocalId: '26895342307', // arbitrary magic number
  projectUrlName: 'Static Analysis',
  traceProjectLocalId: '839405571648239', // arbitrary magic number
}

// we could potentially make this path a bit less user-friendly to prevent crawling or accidental discovery
export const CCO_PATH_CHART = '/cco-statistics'

// parses blob data into TraceDataDto
const reader = new FileReader()

/*

interface Region {
  type: string
  endpoint: string
  httpMethod?: string
}

interface UsageRecord {
  threadName: string
  region: Region
  time: number
  count: number
  callsStart: number[]
  callsEnd: number[]
  logItems: any[][]
}


interface EndpointSummary {
  endpoint: string
  totalTime: number
  totalCount: number
  callsStart: number[]
  callsEnd: number[]
  logItems: any[][]
}

 */

interface LogItem {
  principal?: string
  accessToken?: string
  runnable?: number
  callable?: number
  objectId?: number
}

export interface ChartData {
  x: number
  yCPU: number
  yTraffic: number
  yStorage: number
  yActivity: number
}

interface ChartDataDto {
  timeStamps: number[]
  cpuPoints: number[]
  trafficPoints: number[]
  storagePoints: number[]
  activityPoints: number[]
}

interface SummaryDto {
  cpuTime: number
  traffic: number
  storage: number
  count: number
}

function sumSummaries(summaryA: SummaryDto, summaryB: SummaryDto): SummaryDto {
  return {
    cpuTime: summaryA.cpuTime + summaryB.cpuTime,
    traffic: summaryA.traffic + summaryB.traffic,
    storage: summaryA.storage + summaryB.storage,
    count: summaryA.count + summaryB.count,
  }
}

function transformSummary(summary: SummaryDto, view: TableSummaryView): string[] {
  const { cpuTime, traffic, storage, count } = summary

  switch (view) {
    case TableSummaryView.TOTAL:
      return [formatNS(cpuTime), formatData(storage), formatData(traffic), String(count)]
    case TableSummaryView.AVERAGE:
      return [
        formatNS(cpuTime / count),
        formatData(storage / count),
        formatData(traffic / count),
        String(1),
      ]
    case TableSummaryView.COST:
      const computePrice = calculateCompute(cpuTime)
      const storagePrice = calculateStorage(storage)
      const trafficPrice = calculateTraffic(traffic)
      const totalPrice = computePrice + storagePrice + trafficPrice

      return [
        formatPrice(computePrice),
        formatPrice(storagePrice),
        formatPrice(trafficPrice),
        formatPrice(totalPrice),
      ]
  }

  return []
}

function defaultSortUsingSummary(
  { cpuTime: cpuTimeX }: SummaryDto,
  { cpuTime: cpuTimeY }: SummaryDto,
): number {
  return cpuTimeY - cpuTimeX // Descending order
}

interface MethodDto {
  method: string
  summary: SummaryDto
}

interface ListSummariesDto {
  directSummary: SummaryDto
  indirectSummary: SummaryDto
  directMethods: MethodDto[]
  indirectMethods: MethodDto[]
}

interface EndpointSummaryDto extends ListSummariesDto {
  type: string
  endpoint: string
}

interface SessionEndpointSummaryDto extends ListSummariesDto {
  type: string
  endpoint: string
  userSession: string
  start: number
  end: number
}

interface StatisticDataDto {
  entryPointsSummary: EndpointSummaryDto[]
  sessionSummaries: SessionEndpointSummaryDto[]
  graphData: ChartDataDto
}

interface EndpointSessionData extends SummaryDto {
  title: string
}

export interface SessionEndpointData extends SummaryDto {
  title: string
  start: number
  end: number
  indirectMethods: MethodData[]
  directMethods: MethodData[]
}

const enum MethodType {
  DIRECT,
  INDIRECT,
}

interface MethodData extends SummaryDto {
  title: string
  type?: MethodType
}

export interface EndpointData extends SummaryDto {
  title: string
  indirectMethods: MethodData[]
  directMethods: MethodData[]
  sessions: EndpointSessionData[]
}

export interface SessionData extends SummaryDto {
  title: string
  start: number
  end: number
  endpoints: SessionEndpointData[]
}

interface DashboardData {
  endpointsData: EndpointData[]
  sessionsData: SessionData[]
  chartsData: ChartData[]
}

const namedLinks: NamedLinkDto[] = [
  {
    id: '0',
    toName: ' <init>',
    fromName: ' call',
    type: NamedLinkType.OBJECT,
    isEditable: false,
  },
  {
    id: '1',
    toName: ' <init>',
    fromName: ' run',
    type: NamedLinkType.OBJECT,
    isEditable: false,
  },
  {
    id: '2',
    toName: ' <SCHEDULE>',
    fromName: ' call',
    type: NamedLinkType.RUNNABLE,
    isEditable: false,
  },
  {
    id: '3',
    toName: ' <SCHEDULE>',
    fromName: ' run',
    type: NamedLinkType.RUNNABLE,
    isEditable: false,
  },
]

function repositionSlices(slices: SliceDto[]) {
  for (let i = 0; i < slices.length; i++) {
    const outerSlice = slices[i]
    for (let j = i + 1; j < slices.length; j++) {
      const innerSlice = slices[j]
      if (innerSlice.start >= outerSlice.start && innerSlice.end <= outerSlice.end) {
        slices.splice(j, 1)
        j--
        outerSlice.children.push(innerSlice)
      } else if (innerSlice.start > outerSlice.end) {
        break
      }
    }
    repositionSlices(outerSlice.children)
  }
}

function parseLogItems(logItems: string[]): LogItem {
  const logItem: LogItem = {}

  for (const keyValuePair of logItems) {
    const [key, value] = keyValuePair.split('=')
    switch (key) {
      case 'principal':
        logItem.principal = value
        break
      case 'access_token':
        logItem.accessToken = value
        break
      case 'runnable':
        logItem.runnable = Number(value)
        break
      case 'callable':
        logItem.callable = Number(value)
        break
      case 'objId':
        logItem.objectId = Number(value)
        break
      // ... add more cases for other keys you might need
    }
  }

  return logItem
}

const parseMethodDta =
  (type: MethodType) =>
  (methodDto: MethodDto): MethodData => {
    const { method, summary } = methodDto
    return { title: method, ...summary, type }
  }

function groupSessions(rawSessionEndpoints: SessionEndpointSummaryDto[]): SessionData[] {
  const sessionMap = rawSessionEndpoints.reduce((acc, sessionEndpointDto) => {
    const {
      userSession,
      start,
      end,
      endpoint,
      directSummary,
      indirectSummary,
      directMethods,
      indirectMethods,
    } = sessionEndpointDto

    if (!acc[userSession]) {
      acc[userSession] = {
        title: userSession,
        start: start,
        end: end,
        ...sumSummaries(directSummary, indirectSummary),
        endpoints: [
          {
            title: endpoint,
            start: start,
            end: end,
            directMethods: directMethods.map(parseMethodDta(MethodType.DIRECT)),
            indirectMethods: (indirectMethods || []).map(parseMethodDta(MethodType.INDIRECT)),
            ...sumSummaries(directSummary, indirectSummary),
          },
        ],
      }
    } else {
      const existingSession = acc[userSession]
      const {
        title: sessionTitle,
        start: sessionStart,
        end: sessionEnd,
        endpoints: sessionEndpoints,
        ...summary
      } = existingSession

      sessionEndpoints.push({
        title: endpoint,
        start: start,
        end: end,
        directMethods: directMethods.map(parseMethodDta(MethodType.DIRECT)),
        indirectMethods: (indirectMethods || []).map(parseMethodDta(MethodType.INDIRECT)),
        ...sumSummaries(directSummary, indirectSummary),
      })

      acc[userSession] = {
        title: sessionTitle,
        start: Math.min(sessionStart, start),
        end: Math.max(sessionEnd, end),
        endpoints: sessionEndpoints,
        ...sumSummaries({ ...summary }, sumSummaries(directSummary, indirectSummary)),
      }
    }

    return acc
  }, {} as Record<string, SessionData>)

  return Object.values(sessionMap)
}

function parseToDashboard(data: StatisticDataDto): DashboardData {
  const chartsData: ChartData[] = []
  data.graphData.timeStamps.forEach((time, index) => {
    chartsData.push({
      x: time,
      yCPU: data.graphData.cpuPoints[index],
      yTraffic: data.graphData.trafficPoints[index],
      yStorage: data.graphData.storagePoints[index],
      yActivity: data.graphData.activityPoints[index],
    })
  })

  const sessionsData: SessionData[] = []
  if (data.sessionSummaries && data.sessionSummaries.length) {
    sessionsData.push(...groupSessions(data.sessionSummaries))
  }

  const endpointsData: EndpointData[] = []
  if (data.entryPointsSummary && data.entryPointsSummary.length) {
    for (const entryPoint of data.entryPointsSummary) {
      const { endpoint, directSummary, directMethods, indirectSummary, indirectMethods } =
        entryPoint
      const sessions = Object.entries(
        data.sessionSummaries
          .filter((sessionEntrypoint) => sessionEntrypoint.endpoint === entryPoint.endpoint)
          .reduce(
            (
              acc: Record<string, { directSummary: SummaryDto; indirectSummary: SummaryDto }>,
              sessionEntrypoint,
            ) => {
              const {
                userSession,
                directSummary: sessionDirectSummary,
                indirectSummary: sessionIndirectSummary,
              } = sessionEntrypoint

              if (!acc[userSession]) {
                acc[userSession] = {
                  directSummary: { ...sessionDirectSummary },
                  indirectSummary: { ...sessionIndirectSummary },
                }
              } else {
                acc[userSession].directSummary = sumSummaries(
                  acc[userSession].directSummary,
                  sessionDirectSummary,
                )
                acc[userSession].indirectSummary = sumSummaries(
                  acc[userSession].indirectSummary,
                  sessionIndirectSummary,
                )
              }

              return acc
            },
            {},
          ),
      )
        .map(
          ([userSession, summaries]: [
            string,
            { directSummary: SummaryDto; indirectSummary: SummaryDto },
          ]) => ({
            title: userSession,
            ...sumSummaries(summaries.directSummary, summaries.indirectSummary),
          }),
        )
        .sort(defaultSortUsingSummary)
      const endpointData: EndpointData = {
        title: endpoint,
        ...sumSummaries(directSummary, indirectSummary),
        directMethods: directMethods.map(parseMethodDta(MethodType.DIRECT)),
        indirectMethods: (indirectMethods || []).map(parseMethodDta(MethodType.INDIRECT)),
        sessions: sessions,
      }
      endpointsData.push(endpointData)
      endpointsData.sort(defaultSortUsingSummary)
    }
  }

  return { chartsData, endpointsData, sessionsData }
}

function transformToTimelineData(originalData: any): TraceDataDto {
  const threads: ThreadDto[] = []
  let threadIdCounter = 0
  let globalSliceIdCounter = 1

  // Group records by threadName
  const recordsByThreadName = originalData.usageRecords.reduce(
    (acc: { [x: string]: any[] }, record: { threadName: any }) => {
      const { threadName } = record
      if (!acc[threadName]) {
        acc[threadName] = []
      }
      acc[threadName].push(record)
      return acc
    },
    {} as Record<string, any[]>,
  )

  let minStartTime = Infinity
  for (const threadName in recordsByThreadName) {
    const records = recordsByThreadName[threadName]
    const firstRecord = records[0]
    if (firstRecord) {
      minStartTime = Math.min(minStartTime, firstRecord.callsStart[0])
    }
  }

  // Process each thread
  for (const threadName in recordsByThreadName) {
    if (threadName !== 'default-nioEventLoopGroup-1-5') {
      //continue
    }
    const records = recordsByThreadName[threadName]
    const thread: ThreadDto = {
      id: threadIdCounter++,
      name: threadName,
      slices: [],
      isAsync: false,
    }

    // Create initial slices
    for (const record of records) {
      for (const [index, startTime] of record.callsStart.entries()) {
        const endTime = record.callsEnd[index]
        const logItems = parseLogItems(record.logItems[index])

        const objectId = logItems.objectId ?? null
        let scheduleId = null
        const callableId = logItems.callable ?? null
        const runnableId = logItems.runnable ?? null
        const regionType = record.region.type
        let name = ''
        let args: { key: string; value: string }[] = []
        if (regionType === 'EndpointDto') {
          const { endpoint, httpMethod, method } = record.region
          const { className, methodName, methodDesc } = method
          name = `${regionType} ${endpoint}`
          args = [
            { key: '00-endpointDetails.00-url', value: endpoint },
            { key: '00-endpointDetails.01-httpMethod', value: httpMethod },
            { key: '01-methodDetails.00-className', value: className },
            { key: '01-methodDetails.01-name', value: methodName },
            { key: '01-methodDetails.02-desc', value: methodDesc },
          ]
        } else if (regionType === 'MethodDto') {
          const { className, methodName, methodDesc } = record.region
          const parts = className.split('/')
          const lastPart = parts.length > 0 ? parts[parts.length - 1] : className

          name = `${lastPart} ${methodName}`

          args = [
            { key: '00-methodDetails.00-className', value: className },
            { key: '00-methodDetails.01-name', value: methodName },
            { key: '00-methodDetails.02-desc', value: methodDesc },
          ]
        }
        if (runnableId !== null || callableId !== null) {
          name = `${name} <SCHEDULE>`
          args.push({ key: 'scheduleID', value: String(runnableId ?? callableId) })
          scheduleId = runnableId ?? callableId
        }

        thread.slices.push({
          id: globalSliceIdCounter++,
          closureId: null,
          objectId: objectId,
          runnableId: scheduleId,
          name: name,
          start: startTime - minStartTime,
          end: endTime - minStartTime,
          children: [],
          arguments: args,
        })
      }
    }
    thread.slices.sort((a, b) => a.start - b.start)
    repositionSlices(thread.slices)
    threads.push(thread)
  }

  return { threads, metadata: { totalTime: 0 } }
}

const stopMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation()
}

export const CCOPage = () => {
  const [appID, setAppID] = useState<null | string>(null)
  const [dashDataDto, setDashDataDto] = useState<null | any>(null)
  const [rawDataDto, setRawDataDto] = useState<null | any>(null)
  const [sessionData, setSessionData] = useState<SessionData | null>(null)
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    chartsData: [],
    endpointsData: [],
    sessionsData: [],
  })

  const [searchValue, setSearchValue] = useState<string>('')
  const [summaryViewMode, setSummaryViewMode] = useState<TableSummaryView>(TableSummaryView.TOTAL)
  const [traceViewMode, toggleTraceViewMode] = useState<boolean>(false)

  const [apiMode1, setAPIMode] = useState<string>('last')
  const apiMode = 'static'

  const api = useApi()
  const toaster = useToaster()

  const chartDataStore = useMemo(
    () => new ChartDataStore(api, tracePageParams, new PsChartSettings()),
    [api],
  )
  if (chartDataStore == null) {
    throw new Error('ChartDataStore has not been found')
  }

  const psChartStore = useMemo(
    () =>
      new PsChartStore(
        chartDataStore,
        chartDataStore.settings,
        api,
        tracePageParams,
        toaster,
        chartDataStore.traceDataStore,
        chartDataStore.hStateStore,
        chartDataStore.videoDataStore,
        chartDataStore.annotationsDataStore,
        chartDataStore.flagsDataStore,
        false,
        true,
      ),
    [api, toaster, chartDataStore],
  )

  useEffect(() => {
    fetch(`/v1/sessions`)
      .then((response) => response.json())
      .then((sessions) => {
        setAppID(sessions[0].id)
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  /*
  const fetchRawSummaryData = useCallback(() => {
    if (appID !== null) {
      fetch(`/v1/applications/${appID}/last-state`)
        .then((response) => response.json())
        .then((incomingData) => setRawDataDto(incomingData))
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [appID])
*/

  const fetchDashboardData = useCallback(() => {
    if (appID !== null) {
      fetch(`/v1/applications/${appID}/state-summary/${apiMode}`)
        .then((response) => response.json())
        .then((incomingData) => setDashDataDto(incomingData))
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [apiMode, appID])

  const fetchTraceData = useCallback(() => {
    if (appID !== null) {
      fetch(`/v1/applications/${appID}/state-trace/${apiMode}`)
        .then((response) => response.json())
        .then((incomingTrace) => setRawDataDto(incomingTrace))
        .catch((error) => console.error('Error fetching data:', error))
    }
  }, [apiMode, appID])

  useEffect(() => {
    // Fetch data initially
    fetchDashboardData()
    fetchTraceData()

    let interval: NodeJS.Timeout | number | undefined

    // Only set up the interval if apiMode is not "static"
    if (apiMode !== 'static') {
      interval = setInterval(() => {
        if (!traceViewMode) {
          fetchDashboardData()
          fetchTraceData()
        }
      }, 10000)
    }

    // Clear the interval when the component unmounts or apiMode changes
    return () => {
      if (interval !== undefined) {
        clearInterval(interval as NodeJS.Timeout)
      }
    }
  }, [appID, apiMode, traceViewMode, fetchDashboardData, fetchTraceData])

  useEffect(() => {
    psChartStore.chartSettings.addEventListeners()
    return () => psChartStore.chartSettings.removeEventListeners()
  }, [psChartStore])

  useEffect(() => {
    if (dashDataDto != null) {
      runInAction(() => {
        setDashboardData(parseToDashboard(dashDataDto))
      })
    }
  }, [dashDataDto])

  useEffect(() => {
    if (rawDataDto != null) {
      runInAction(() => {
        const timelineData =
          rawDataDto.threads !== undefined ? rawDataDto : transformToTimelineData(rawDataDto)

        chartDataStore.traceDataStore.process(timelineData)
        psChartStore.hState.setXStartAndXEnd(psChartStore.hState.xMin, psChartStore.hState.xMax)
        psChartStore.reloadConnections(namedLinks)
      })
    }
  }, [rawDataDto, psChartStore, chartDataStore.traceDataStore])

  const processFile = (fileEvent: ChangeEvent<HTMLInputElement>) => {
    try {
      if (fileEvent?.target?.files) {
        reader.onload = (readEvent: ProgressEvent<FileReader>) => {
          if (readEvent.target?.result) {
            const fileContents = JSON.parse(String(readEvent.target.result))
            return setDashDataDto(fileContents)
          }
        }
        return reader.readAsText(fileEvent.target.files[0])
      }
    } catch (e) {
      console.error(e)
      toast.error(
        toastWithTitle(
          'Error processing trace file',
          'Could not render flame chart, please try a new file.',
          true,
        ),
      )
    }
  }

  const handleSelectSession = (userSession: string) => {
    const selectedSession = dashboardData.sessionsData.find(({ title }) => title === userSession)
    if (selectedSession !== undefined) {
      setSessionData(selectedSession)
      psChartStore.hState.setXStartAndXEnd(selectedSession.start, selectedSession.end)
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const handleResetSession = () => {
    setSessionData(null)
  }

  const handleTableSummaryViewChange = (view: TableSummaryView) => {
    setSummaryViewMode(view)
  }

  const handleSearchChange = (input: string) => {
    setSearchValue(input)
  }

  const toggleTraceView = () => {
    toggleTraceViewMode(!traceViewMode)
  }

  const handleToggleAPIMode = () => {
    setAPIMode((prevState) => (prevState === 'last' ? 'static' : 'last'))
  }

  return (
    <>
      {traceViewMode ? (
        <TimelineView
          sessions={dashboardData.sessionsData}
          sessionData={sessionData}
          onSelectSession={handleSelectSession}
          psChartStore={psChartStore}
          toggleTraceView={toggleTraceView}
        />
      ) : (
        <StatsView
          dashboardData={dashboardData}
          sessionData={sessionData}
          onSelectSession={handleSelectSession}
          onResetSession={handleResetSession}
          onSummaryViewChange={handleTableSummaryViewChange}
          onSearchChange={handleSearchChange}
          search={searchValue}
          summaryViewMode={summaryViewMode}
          toggleTraceView={toggleTraceView}
          processFile={processFile}
          toggleAPIMode={handleToggleAPIMode}
        />
      )}
    </>
  )
}

const StatsView = ({
  processFile,
  dashboardData,
  sessionData,
  summaryViewMode,
  search,
  onSelectSession,
  onResetSession,
  onSummaryViewChange,
  onSearchChange,
  toggleTraceView,
  toggleAPIMode,
}: {
  processFile: (fileEvent: ChangeEvent<HTMLInputElement>) => void
  onSelectSession: (userSession: string) => void
  onResetSession: () => void
  toggleTraceView: () => void
  toggleAPIMode: () => void
  dashboardData: DashboardData
  sessionData: SessionData | null
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  const showChartsBoard = sessionData === null
  return (
    <div className="relative w-full min-w-[900px] min-h-full bg-dark-default">
      <div
        className="absolute right-0 top-50 w-[50px] h-[50px] text-[14px] leading-[19px] font-semibold text-[#292929] cursor-pointer"
        onClick={toggleAPIMode}
      >
        π
      </div>
      <div className="px-[24px]">
        <Application processFile={processFile} />
      </div>
      {showChartsBoard && (
        <>
          <div className="px-[24px] h-[64px] flex items-center">
            <span className="text-[14px] leading-[19px] font-semibold">Dashboard</span>
          </div>
          <div className="px-[24px] border-y-[1px] border-y-[#383838]">
            <ChartsBoard graphStats={dashboardData.chartsData} />
          </div>
        </>
      )}

      <div className="px-[24px]">
        <Table
          endpoints={dashboardData.endpointsData}
          session={sessionData}
          search={search}
          summaryViewMode={summaryViewMode}
          onSelectSession={onSelectSession}
          onResetSession={onResetSession}
          onSearchChange={onSearchChange}
          onSummaryViewChange={onSummaryViewChange}
          toggleViewTrace={toggleTraceView}
        />
      </div>
    </div>
  )
}

const Application = ({
  processFile,
}: {
  processFile: (fileEvent: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <div className="h-[96px] flex align-middle">
      <div className="flex items-center">
        <div className="h-[42px] w-[42px] flex justify-center items-center mr-[5px] bg-electro rounded-[4px]">
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.36 0H1.32V4.78261H0V11.3043H1.32V20H22V13.4783H8.36V11.3043H22V4.78261H8.36V0Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex pl-[16px]">
          <div className="pr-[24px] text-[30px] leading-[32px] font-bold border-r-[1px] border-r-[#383838]">
            ProductScience
          </div>
          <div className="pl-[24px] text-[12px] leading-[16px] font-semibold">
            <span className="mb-[2px]">Web Application</span>
            <br />
            <span className="text-gray-service">Java/Kotlin</span>
          </div>
        </div>
        <div>
          <UploadFileInput className="hidden" onChange={processFile} />
        </div>
      </div>
    </div>
  )
}

const KB_THRESHOLD = 1024
const MB_THRESHOLD = 1024 * KB_THRESHOLD
const GB_THRESHOLD = 1024 * MB_THRESHOLD
const TB_THRESHOLD = 1024 * GB_THRESHOLD

const calculateCompute = (nanoseconds: number): number => {
  const nanosecondsPerMinute = 60 * 1e9
  const totalMinutes = nanoseconds / nanosecondsPerMinute
  const hours = Math.floor(totalMinutes / 60)
  return hours * 0.052
}

const calculateTraffic = (bytes: number): number => {
  return (bytes / GB_THRESHOLD) * 0.02
}

const calculateStorage = (bytes: number): number => {
  return (bytes / GB_THRESHOLD) * 0.24
}

const formatPrice = (price: number): string => {
  if (price < 0.01) {
    return 'less than a cent'
  }

  // Convert the number to a string with comma delimiters
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

const formatNS = (nanoseconds: number, keepZero = false): string => {
  if (nanoseconds === 0 && !keepZero) {
    return '-'
  }

  const nanosecondsPerSecond = 1e9
  const nanosecondsPerMinute = 60 * nanosecondsPerSecond
  const totalMinutes = nanoseconds / nanosecondsPerMinute
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)
  const seconds = Math.floor((nanoseconds % nanosecondsPerMinute) / nanosecondsPerSecond)
  const milliseconds = Math.floor((nanoseconds % nanosecondsPerSecond) / 1e6)

  if (nanoseconds < 1e6) {
    return 'less than 1ms'
  }

  let formattedTime = ''

  // Format as "Mm Ss" if less than 10 minutes and no hours
  if (hours === 0 && totalMinutes < 10) {
    if (minutes > 0) {
      formattedTime += `${minutes} m `
    }
    if (seconds > 1) {
      formattedTime += `${seconds} s`
    }
    // Only add milliseconds if there are no minutes
    if (minutes === 0) {
      formattedTime += ` ${milliseconds} ms`
    }

    return formattedTime.trim() // Trim any extra space
  }

  // Format as "Hh Mm" if there are hours or 10 minutes or more
  if (hours > 0) {
    formattedTime += `${hours} h `
  }
  formattedTime += `${minutes} m`

  return formattedTime
}

const yFormatCPU = (d: NumberValue) => {
  if (d === 0) {
    return ''
  }
  const nanoseconds = d as number
  return formatNS(nanoseconds)
}

const formatData = (sizeBytes: number, keepZero = false) => {
  if (sizeBytes === 0 && !keepZero) {
    return '-'
  }

  if (sizeBytes >= TB_THRESHOLD) {
    return `${(sizeBytes / TB_THRESHOLD).toFixed(2)} Tb`
  } else if (sizeBytes >= GB_THRESHOLD) {
    return `${(sizeBytes / GB_THRESHOLD).toFixed(2)} Gb`
  } else if (sizeBytes >= MB_THRESHOLD) {
    return `${(sizeBytes / MB_THRESHOLD).toFixed(2)} Mb`
  } else if (sizeBytes >= KB_THRESHOLD) {
    return `${(sizeBytes / KB_THRESHOLD).toFixed(2)} Kb`
  } else {
    return `${sizeBytes.toFixed(0)} b`
  }
}

const yFormatData = (d: NumberValue) => {
  // Check for 0 value and return empty string
  if (d === 0) {
    return ''
  }

  const sizeBytes = d as number
  return formatData(sizeBytes, true)
}

const yFormatActivity = (d: NumberValue) => {
  // Check for 0 value and return empty string
  if (d === 0) {
    return ''
  }
  return `${Math.floor(d as number)}`
}

const ChartsBoard = ({ graphStats }: { graphStats: ChartData[] }) => {
  return (
    <div className="grid down-1280:grid-cols-2 grid-cols-4 gap-[8px] py-[24px]">
      <LineChart
        title="CPU"
        data={graphStats}
        dataYFormat={yFormatCPU}
        lowColor="#009B8E"
        highColor="#C9FF2A"
      />
      <LineChart
        title="Storage"
        data={graphStats}
        dataYFormat={yFormatData}
        lowColor="#FB6500"
        highColor="#FFC600"
      />
      <LineChart
        title="Traffic"
        data={graphStats}
        dataYFormat={yFormatData}
        lowColor="#503CBB"
        highColor="#009EEE"
      />
      <LineChart
        title="Activity"
        data={graphStats}
        dataYFormat={yFormatActivity}
        lowColor="#671A9E"
        highColor="#B848E8"
      />
    </div>
  )
}

const LineChart = ({
  title,
  data,
  dataYFormat,
  lowColor,
  highColor,
}: {
  title: string
  data: ChartData[]
  dataYFormat: TickFormatter<NumberValue>
  lowColor: string
  highColor: string
}) => {
  return (
    <div className="h-[280px] bg-dark-dark2">
      <div className="h-[46px] px-[16px] flex items-center">
        <span className="text-[16px] leading-[22px] font-semibold">{title}</span>
      </div>
      <div className="h-[234px]">
        <LineGraph
          data={data}
          dataKey={title}
          dataXKey="x"
          dataYKey={`y${title}`}
          dataYFormat={dataYFormat}
          lowColor={lowColor}
          highColor={highColor}
        />
      </div>
    </div>
  )
}

const Table = ({
  endpoints,
  session,
  summaryViewMode,
  search,
  onSelectSession,
  onResetSession,
  onSummaryViewChange,
  onSearchChange,
  toggleViewTrace,
}: {
  endpoints: EndpointData[]
  session: SessionData | null
  onSelectSession: (userSession: string) => void
  onResetSession: () => void
  toggleViewTrace: () => void
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  const isSessionSelected = session !== null

  const filteredEndpoints = search.length
    ? endpoints.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase()))
    : endpoints

  const filteredSessionEndpoints = search.length
    ? session?.endpoints.filter(({ title }, _) =>
        title.toLowerCase().includes(search.toLowerCase()),
      )
    : session?.endpoints

  return (
    <div className="pb-[24px]">
      {isSessionSelected && (
        <TableSessionControls onResetSession={onResetSession} toggleViewTrace={toggleViewTrace} />
      )}
      <TableControls
        search={search}
        summaryViewMode={summaryViewMode}
        onSummaryViewChange={onSummaryViewChange}
        onSearchChange={onSearchChange}
      />
      <TableTitleRow
        type={session !== null ? TableTitleType.SESSION : TableTitleType.ENDPOINT}
        summaryViewMode={summaryViewMode}
      />
      {!isSessionSelected &&
        (filteredEndpoints.length > 0 ? (
          <TableEndpointList
            endpoints={filteredEndpoints}
            summaryViewMode={summaryViewMode}
            onSelectSession={onSelectSession}
          />
        ) : (
          <div className="h-[64px] flex items-center justify-center text-[16px] leading-[22px] font-semibold text-gray-service">
            Empty list
          </div>
        ))}
      {isSessionSelected && (
        <TableSessionCardContainer
          data={[session.title, ...transformSummary({ ...session }, summaryViewMode)]}
          endpoints={filteredSessionEndpoints ?? []}
          summaryViewMode={summaryViewMode}
        />
      )}
    </div>
  )
}

const TableControls = ({
  search,
  summaryViewMode,
  onSummaryViewChange,
  onSearchChange,
}: {
  summaryViewMode: TableSummaryView
  onSummaryViewChange: (view: TableSummaryView) => void
  search: string
  onSearchChange: (input: string) => void
}) => {
  return (
    <div className="h-[64px] flex items-center justify-between">
      <div>
        <TableStateToggleButton value={summaryViewMode} onChange={onSummaryViewChange} />
      </div>
      <div>
        <EndpointsSearch value={search} onChange={onSearchChange} />
      </div>
    </div>
  )
}

const TableSessionControls = ({
  onResetSession,
  toggleViewTrace,
}: {
  onResetSession: () => void
  toggleViewTrace: () => void
}) => {
  return (
    <div className="h-[64px] border-b-[1px] border-y-[#383838] flex items-center justify-between">
      <div className="flex justify-center items-center cursor-pointer" onClick={onResetSession}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#383838" />
          <path d="M14 8L10 12L14 16" stroke="#B3B3B3" />
        </svg>
        <div className="pl-[24px] text-[14px] leading-[19px] font-semibold">User Session</div>
      </div>

      <div
        className="w-[148px] h-[32px] flex items-center justify-center text-[12px] leading-[16px] font-semibold rounded-[4px] border-[1px] border-gray-service cursor-pointer"
        onClick={toggleViewTrace}
      >
        View session in trace
      </div>
    </div>
  )
}

const ColumnText = styled.span<{ right: boolean }>`
  direction: ${({ right }) => (right ? 'rtl' : 'ltl')};
`

const TableRowContainer = ({
  data,
  containerClassName,
  firstTextClassName,
  otherTextClassName,
}: {
  data: string[]
  containerClassName: string
  firstTextClassName: string
  otherTextClassName: string
}) => {
  return (
    <div
      className={twMerge('px-[8px] mb-[2px] rounded-[4px]', 'grid grid-cols-8', containerClassName)}
    >
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'} flex items-center`}
          >
            <ColumnText
              className={twMerge('px-[8px]', index === 0 ? firstTextClassName : otherTextClassName)}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const TableEndpointCardContainer = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onSelectSession,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onSelectSession: (userSession: string) => void
}) => {
  const [methodsExpander, setMethodsExpander] = useState(1)
  const [sessionsExpander, setSessionsExpander] = useState(1)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  const expandSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander((prevExpander) => prevExpander + 1)
    },
    [setSessionsExpander],
  )

  const collapseSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander(1)
    },
    [setSessionsExpander],
  )

  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
            >
              <ColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </ColumnText>
            </div>
          ))}
      </div>
      <>
        {methods
          .slice(0, 10 * methodsExpander)
          .map(({ title, cpuTime, storage, traffic, count, type }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              methodType={type}
            >
              {methodsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {methods.length / 10 >= methodsExpander && index === 10 * methodsExpander - 1 && (
                <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
        {sessions
          .slice(0, 10 * sessionsExpander)
          .map(({ title, cpuTime, storage, traffic, count }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              lastItem={index === Math.min(sessions.length - 1, 10 * sessionsExpander - 1)}
              onSelect={onSelectSession}
            >
              {index === 0 && (
                <span className="text-[12px] leading-[17px] font-semibold text-white">
                  User Sessions
                </span>
              )}
              {sessionsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseSessions} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {sessions.length / 10 >= sessionsExpander && index === 10 * sessionsExpander - 1 && (
                <TableCardChildButton onClick={expandSessions} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
      </>
    </div>
  )
}

const TableSessionEndpoint = ({
  endpoint,
  summaryViewMode,
}: {
  endpoint: SessionEndpointData
  summaryViewMode: TableSummaryView
}) => {
  const { title, directMethods, indirectMethods, ...endpointSummary } = endpoint
  const [methodsExpander, setMethodsExpander] = useState(1)
  const methods = [...directMethods, ...indirectMethods].sort(defaultSortUsingSummary)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  return (
    <>
      <TableCardChild
        data={[title, ...transformSummary({ ...endpointSummary }, summaryViewMode)]}
      />
      {methods
        .slice(0, 10 * methodsExpander)
        .map(({ title: methodTitle, type, ...methodSummary }, methodIndex) => (
          <TableCardChild
            key={methodIndex}
            data={[methodTitle, ...transformSummary({ ...methodSummary }, summaryViewMode)]}
            methodType={type}
            sub
          >
            {methodsExpander > 1 &&
              methodIndex === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                  Collapse
                </TableCardChildButton>
              )}
            {methods.length / 10 >= methodsExpander && methodIndex === 10 * methodsExpander - 1 && (
              <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                Expand
              </TableCardChildButton>
            )}
          </TableCardChild>
        ))}
    </>
  )
}

const TableSessionCardContainer = ({
  data,
  endpoints,
  summaryViewMode,
}: {
  data: string[]
  endpoints: SessionEndpointData[]
  summaryViewMode: TableSummaryView
}) => {
  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
            >
              <ColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </ColumnText>
            </div>
          ))}
      </div>
      {endpoints.map((sessionEndpoint, index) => (
        <TableSessionEndpoint
          key={index}
          endpoint={sessionEndpoint}
          summaryViewMode={summaryViewMode}
        />
      ))}
    </div>
  )
}

const MethodTypeIcon = ({ methodType }: { methodType: MethodType }) => {
  return (
    <div
      className={twMerge(
        'min-w-[52px] h-[16px] rounded-[4px]',
        'text-dark-dark5 text-[11px] leading-[15px] font-semibold text-center',
        methodType === MethodType.DIRECT && 'bg-[#54BEBE]',
        methodType === MethodType.INDIRECT && 'bg-[#A66CCA]',
      )}
    >
      {methodType === MethodType.DIRECT ? 'Direct' : 'Indirect'}
    </div>
  )
}

const TableCardChildButton = styled.div`
  ${tw`w-[63px] h-[24px] rounded-[20px] border-[1px] border-[#B3B3B3] text-[12px] leading-[15px] flex justify-center items-center`}
`

const TableCardChild = ({
  data,
  methodType,
  lastItem = false,
  sub = false,
  onSelect,
  children,
}: {
  data: string[]
  methodType?: MethodType
  lastItem?: boolean
  sub?: boolean
  onSelect?: (title: string) => void
  children?: React.ReactNode
}) => {
  const clickable = onSelect !== undefined

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (clickable) {
      event.stopPropagation()
      onSelect(data[0])
    }
  }

  return (
    <div
      className={twMerge(
        'h-[40px]',
        'grid grid-cols-8',
        clickable && 'cursor-pointer hover:bg-dark-dark3',
      )}
      onClick={handleClick}
      onMouseDown={stopMouseDown}
    >
      <>
        {sub && <div className={twMerge('px-[8px]', 'col-span-1', 'flex items-center')} />}
        <div className={twMerge('px-[8px]', 'col-span-1', 'flex items-center')}>{children}</div>
      </>
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={twMerge(
              index === 0 && !sub && 'col-span-3 *:truncate',
              index === 0 && sub && 'col-span-2 *:truncate',
              index > 0 && 'col-span-1',
              !lastItem && 'border-b-[1px] border-b-[#383838]',
              'flex items-center',
            )}
          >
            {methodType !== undefined && index === 0 && <MethodTypeIcon methodType={methodType} />}
            <ColumnText
              className={twMerge('px-[8px]', 'text-[12px] leading-[17px] text text-gray-service')}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const enum TableTitleType {
  ENDPOINT,
  SESSION,
}

const TableTitleRow = ({
  type,
  summaryViewMode,
}: {
  type: TableTitleType
  summaryViewMode: TableSummaryView
}) => {
  const countOrTotal = summaryViewMode === TableSummaryView.COST ? 'Total' : 'Count'
  const endpointTitles = ['API', 'CPU time', 'Storage', 'Traffic', countOrTotal]
  const sessionTitle = ['Session', 'API', 'CPU time', 'Storage', 'Traffic', countOrTotal]

  const titles = type === TableTitleType.ENDPOINT ? endpointTitles : sessionTitle

  return (
    <div
      className={twMerge(
        'px-[8px] mb-[2px] rounded-[4px]',
        'grid grid-cols-8',
        'bg-dark-dark2 h-[40px]',
      )}
    >
      {titles.length &&
        titles.map((item, index) => (
          <div
            key={index}
            className={twMerge(
              type === TableTitleType.ENDPOINT && index === 0 && 'col-span-4 *:truncate',
              type === TableTitleType.SESSION && index === 1 && 'col-span-3 *:truncate',
              ((type === TableTitleType.SESSION && index !== 1) ||
                (type === TableTitleType.ENDPOINT && index !== 0)) &&
                'col-span-1',
              'flex items-center',
            )}
          >
            <ColumnText
              className={twMerge('px-[8px]', 'text-[12px] leading-[17px] text-gray-service')}
              right={index === 0}
            >
              {item}
            </ColumnText>
          </div>
        ))}
    </div>
  )
}

const TableEndpointList = ({
  endpoints,
  summaryViewMode,
  onSelectSession,
}: {
  endpoints: EndpointData[]
  summaryViewMode: TableSummaryView
  onSelectSession: (userSession: string) => void
}) => {
  return (
    <>
      {endpoints.map(({ title, directMethods, indirectMethods, sessions, ...summary }, index) => {
        const methods = [...directMethods, ...indirectMethods].sort(defaultSortUsingSummary)
        return (
          <TableEndpointElement
            key={index}
            data={[title, ...transformSummary({ ...summary }, summaryViewMode)]}
            methods={methods}
            sessions={sessions}
            summaryViewMode={summaryViewMode}
            onSelectSession={onSelectSession}
          />
        )
      })}
    </>
  )
}

const TableEndpointElement = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onSelectSession,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onSelectSession: (userSession: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime

    // Threshold duration to differentiate between click and text selection
    const threshold = 200 // milliseconds

    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime, setIsOpen])

  return (
    <div onMouseDown={handleMouseDown} onMouseUp={handleClick}>
      {!isOpen ? (
        <TableRowContainer
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointCardContainer
          data={data}
          methods={methods}
          sessions={sessions}
          summaryViewMode={summaryViewMode}
          onSelectSession={onSelectSession}
        />
      )}
    </div>
  )
}

const TimelineView = ({
  sessionData: selectedSession,
  sessions,
  psChartStore,
  toggleTraceView,
}: {
  sessions: SessionData[]
  sessionData: SessionData | null
  psChartStore: PsChartStore
  toggleTraceView: () => void
}) => {
  return (
    <main className="flex flex-grow min-h-full alwaysShowScrollbar">
      <div className="flex relative flex-grow overflow-hidden">
        <SessionsLeftBar
          selectedSession={selectedSession}
          sessions={sessions}
          psChartStore={psChartStore}
          toggleTraceView={toggleTraceView}
        />
        <div className="flex flex-grow flex-col overflow-hidden">
          <ChartExtensions
            psChartStore={psChartStore}
            tracePageParams={psChartStore.chartPageParams}
          />
          <ScrolledChart psChartStore={psChartStore} />
        </div>
        <div className="absolute right-[8px] bottom-[8px] flex flex-row">
          <div className="mx-[1px]">
            <SearchAction psChartStore={psChartStore} />
          </div>
          <div className="mx-[1px]">
            <RenderTypeModeAction psChartStore={psChartStore} />
          </div>
        </div>
      </div>
      <DetailsView psChartStore={psChartStore} />
    </main>
  )
}

function UploadFileInput(props: {
  className: string
  onChange: (fileEvent: ChangeEvent<HTMLInputElement>) => void
}) {
  return (
    <div className={props.className}>
      <label
        htmlFor="upload"
        className="flex items-center justify-center px-2 bg-electro hover:cursor-pointer rounded"
      >
        Upload
      </label>
      <input
        onChange={props.onChange}
        id="upload"
        type="file"
        placeholder="upload trace"
        className="hidden"
      />
    </div>
  )
}
